import { preDownloadSignatures, preDownloadEmails, preDownloadSmsList, preDownloadDocuments } from '../actions/application';
import i18n from 'i18next';

// tenia 94 left y 5 width
// const coordinates = [
//   {
//     left: 94,
//     top: 35,
//     height: [30],
//     width: 5
//   },
//   {
//     left: 94,
//     top: 10,
//     height: [30, 60],
//     width: 5
//   },
//   {
//     left: 94,
//     top: 6,
//     height: [25, 37, 68],
//     width: 5
//   },
//   {
//     left: 94,
//     top: 2,
//     height: [22, 26, 52, 76],
//     width: 5
//   },
//   {
//     left: 94,
//     top: 1,
//     height: [1, 20, 39, 58, 77],
//     width: 5
//   }
// ];


const coordinates = [
  {
    left: 94,
    top: [35],
    height: 30,
    width: 5
  },
  {
    left: 94,
    top: [10, 60],
    height: 30,
    width: 5
  },
  {
    left: 94,
    top: [6, 37, 68],
    height: 25,
    width: 5
  },
  {
    left: 94,
    top: [2, 26, 52, 76],
    height: 22,
    width: 5
  },
  {
    left: 94,
    top: [1, 20, 39, 58, 77],
    height: 18,
    width: 5
  },
  {
    left: 94,
    top: [1, 17, 33, 49, 65, 81],
    height: 15,
    width: 5
  },
  {
    left: 94,
    top: [1, 15, 29, 43, 57, 71, 85],
    height: 13,
    width: 5
  },
  {
    left: 94,
    top: [1, 13, 25, 37, 49, 61, 73, 85],
    height: 11,
    width: 5
  },
  {
    left: 94,
    top: [1, 12, 23, 34, 45, 56, 67, 78, 89],
    height: 10,
    width: 5
  },
  {
    left: 94,
    top: [1, 10, 19, 28, 37, 46, 55, 64, 73, 82],
    height: 8,
    width: 5
  }
];


/*
  LEFEBVRE SIGNATURE BACKEND API CALLS
*/
/*
   _____ _                   _                    ____             _                  _ 
  / ____(_)                 | |                  |  _ \           | |                | |
 | (___  _  __ _ _ __   __ _| |_ _   _ _ __ ___  | |_) | __ _  ___| | _____ _ __   __| |
  \___ \| |/ _` | '_ \ / _` | __| | | | '__/ _ \ |  _ < / _` |/ __| |/ / _ \ '_ \ / _` |
  ____) | | (_| | | | | (_| | |_| |_| | | |  __/ | |_) | (_| | (__|   <  __/ | | | (_| |
 |_____/|_|\__, |_| |_|\__,_|\__|\__,_|_|  \___| |____/ \__,_|\___|_|\_\___|_| |_|\__,_|
            __/ |                                                                       
           |___/                                                                                                                       
*/

export const getUserSignatures = async (userId, auth, client) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");
    myHeaders.append("Authorization", `${auth}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${window.API_SIGN_GATEWAY}/Signatures/${client}/${userId}`, requestOptions)
      .then(response => response.json())
      .then(result => resolve(result))
      .catch(error => {
        reject(error);
      });
  })
};

// Creates a new user with empty signatures or with a new signature
export const createUser = async (userId, auth, client, brandings = [], signatures = []) => {
  var myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");
    myHeaders.append("Content-Type", "application/json-patch+json");
    myHeaders.append("Content-Type", "text/plain");
    myHeaders.append("Authorization", `${auth}`);


  var raw = `{
  \n  "user\": \"${userId}\",
  \n  \"availableSignatures\": 0,
  \n  \"brandings\": ${JSON.stringify(brandings)},
  \n  \"signatures\": ${JSON.stringify(signatures)}
  \n}`;

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  fetch(`${window.API_SIGN_GATEWAY}/Signatures/${client}/${userId}/add`, requestOptions)
    .then(response => {
      response.text()
    })
    .then(result => null)
    .catch(error => console.log('error', error));
}

// Adds or updates a signature of a given user
export const addOrUpdateSignature = async (userId, externalId, guid, app, createdAt, lexAccess, documents, auth, client) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "text/plain");
  myHeaders.append("Content-Type", "application/json-patch+json");
  myHeaders.append("Content-Type", "text/plain");
  myHeaders.append("Authorization", `${auth}`);


  var raw = `{
    \"externalId\": \"${externalId}\",
    \"guid\": \"${guid}\",
    \"app\": \"${app}\",
    \"createdAt\": \"${createdAt}\",
    \"lex\": ${JSON.stringify(lexAccess)},
    \"documents\": ${JSON.stringify(documents)}
  }`;

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  fetch(`${window.API_SIGN_GATEWAY}/Signatures/${client}/${userId}/signature/addorupdate`, requestOptions)
    .then(response => {
      response.text()
    })
    .then(result => null)
    .catch(error => console.log('error', error));
}

export const addOrUpdateBranding = async (user, brandingInfo, auth, client) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");
    myHeaders.append("Content-Type", "application/json-patch+json");
    myHeaders.append("Content-Type", "text/plain");
    myHeaders.append("Authorization", `${auth}`);


    var raw = `${JSON.stringify(brandingInfo)}`;
    // \n	\"app\": \"${brandingInfo[0].app}\",
    // \n	\"externalId\": "${brandingInfo[0].externalId}"
    // \n}`;

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${window.API_SIGN_GATEWAY}/Signatures/${client}/${user}/branding/addorupdate`, requestOptions)
      .then(response => {
        if (response.ok){
          return response.json()
        }
        else {
          throw new Error("Status:" + response.status + ' ' + " Headers:" + response.headers);
        }
      })
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error)
      });
  })
}

/*
  ____                      _ _               ____             _                  _ 
 |  _ \                    | (_)             |  _ \           | |                | |
 | |_) |_ __ __ _ _ __   __| |_ _ __   __ _  | |_) | __ _  ___| | _____ _ __   __| |
 |  _ <| '__/ _` | '_ \ / _` | | '_ \ / _` | |  _ < / _` |/ __| |/ / _ \ '_ \ / _` |
 | |_) | | | (_| | | | | (_| | | | | | (_| | | |_) | (_| | (__|   <  __/ | | | (_| |
 |____/|_|  \__,_|_| |_|\__,_|_|_| |_|\__, | |____/ \__,_|\___|_|\_\___|_| |_|\__,_|
                                       __/ |                                        
                                      |___/                                         
*/

export const getBrandingTemplate = async (app, auth) => {
  return new Promise((resolve, reject) => {
    
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `${auth}`);

    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders
    };

    fetch(`${window.API_SIGN_GATEWAY}/Brandings/get/${app}/template`, requestOptions)
      .then(response => {
        if (response.ok){
          return response.json()
        }
        else {
          return response.json()
        }
      })
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  })
}

export const createTemplate = async (templateInfo, auth) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", auth);

    var raw = JSON.stringify(templateInfo);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${window.API_SIGN_GATEWAY}/Brandings/add`, requestOptions)
      .then(response => response.json())
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error)
      });
  })
}

/*
  ______                 _ _   ____             _                  _ 
 |  ____|               (_) | |  _ \           | |                | |
 | |__   _ __ ___   __ _ _| | | |_) | __ _  ___| | _____ _ __   __| |
 |  __| | '_ ` _ \ / _` | | | |  _ < / _` |/ __| |/ / _ \ '_ \ / _` |
 | |____| | | | | | (_| | | | | |_) | (_| | (__|   <  __/ | | | (_| |
 |______|_| |_| |_|\__,_|_|_| |____/ \__,_|\___|_|\_\___|_| |_|\__,_|
                                                                     
*/

export const getUserEmails = async (userId, auth) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");
    myHeaders.append("Authorization", `${auth}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${window.API_SIGN_GATEWAY}/CertifiedEmails/${userId}`, requestOptions)
      .then(response => response.json())
      .then(result => resolve(result))
      .catch(error => {
        reject(error);
      });
  })
};

// Creates a new user with empty signatures or with a new signature
export const createUserEmail = async (userId, auth, brandings = [], certifiedEmails = []) => {
  var myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");
    myHeaders.append("Content-Type", "application/json-patch+json");
    myHeaders.append("Content-Type", "text/plain");
    myHeaders.append("Authorization", `${auth}`);


  var raw = `{
  \n  "user\": \"${userId}\",
  \n  \"brandings\": ${JSON.stringify(brandings)},
  \n  \"certifiedEmails\": ${JSON.stringify(certifiedEmails)}
  \n}`;

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  fetch(`${window.API_SIGN_GATEWAY}/CertifiedEmails/${userId}/add`, requestOptions)
    .then(response => {
      response.text()
    })
    .then(result => null)
    .catch(error => console.log('error', error));
}

// Adds or updates a signature of a given user
export const addOrUpdateEmail = async (userId, externalId, guid, app, createdAt, type, lexAccess, certificates, auth) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "text/plain");
  myHeaders.append("Content-Type", "application/json-patch+json");
  myHeaders.append("Content-Type", "text/plain");
  myHeaders.append("Authorization", `${auth}`);

  var raw = `{
    \n  \"guid\": \"${guid}\",
    \n  \"externalId\": \"${externalId}\",
    \n  \"app\": \"${app}\",
    \n  \"created_at\": \"${createdAt}\",
    \n  \"type\": \"${type}\",
    \n  \"lex\": ${JSON.stringify(lexAccess)},
    \n  \"certificate\": ${JSON.stringify(certificates)}
    \n}`;

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  fetch(`${window.API_SIGN_GATEWAY}/CertifiedEmails/${userId}/email/addorupdate`, requestOptions)
    .then(response => {
      response.text()
    })
    .then(result => null)
    .catch(error => console.log('error', error));
}

export const addOrUpdateBrandingEmail = async (user, brandingInfo, auth) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");
    myHeaders.append("Content-Type", "application/json-patch+json");
    myHeaders.append("Content-Type", "text/plain");
    myHeaders.append("Authorization", `${auth}`);

    var raw = `${JSON.stringify(brandingInfo)}`;
    // \n	\"app\": \"${brandingInfo[0].app}\",
    // \n	\"externalId\": "${brandingInfo[0].externalId}"
    // \n}`;

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${window.API_SIGN_GATEWAY}/CertifiedEmails/${user}/branding/addorupdate`, requestOptions)
      .then(response => {
        if (response.ok){
          return response.json()
        }
        else {
          throw new Error("Status:" + response.status + ' ' + " Headers:" + response.headers);
        }
      })
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error)
      });
  })
}


/*
   _____                 ____             _                  _ 
  / ____|               |  _ \           | |                | |
 | (___  _ __ ___  ___  | |_) | __ _  ___| | _____ _ __   __| |
  \___ \| '_ ` _ \/ __| |  _ < / _` |/ __| |/ / _ \ '_ \ / _` |
  ____) | | | | | \__ \ | |_) | (_| | (__|   <  __/ | | | (_| |
 |_____/|_| |_| |_|___/ |____/ \__,_|\___|_|\_\___|_| |_|\__,_|
                                                                                                                           
*/

export const getUserSms = async (userId, auth) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");
    myHeaders.append("Authorization", `${auth}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${window.API_SIGN_GATEWAY}/CertifiedSms/${userId}`, requestOptions)
      .then(response => response.json())
      .then(result => resolve(result))
      .catch(error => {
        reject(error);
      });
  })
};

// Creates a new user with empty signatures or with a new signature
export const createUserSms = async (userId, auth, certifiedSms = []) => {
  var myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");
    myHeaders.append("Content-Type", "application/json-patch+json");
    myHeaders.append("Content-Type", "text/plain");
    myHeaders.append("Authorization", `${auth}`);


  var raw = `{
  \n  "user\": \"${userId}\",
  \n  \"certifiedSms\": ${JSON.stringify(certifiedSms)}
  \n}`;

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  fetch(`${window.API_SIGN_GATEWAY}/CertifiedSms/${userId}/add`, requestOptions)
    .then(response => {
      response.text()
    })
    .then(result => null)
    .catch(error => console.log('error', error));
}

// Adds or updates a sms of a given user
export const addOrUpdateSms = async (userId, externalId, guid, app, createdAt, type, lexAccess, certificates, auth) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "text/plain");
  myHeaders.append("Content-Type", "application/json-patch+json");
  myHeaders.append("Content-Type", "text/plain");
  myHeaders.append("Authorization", `${auth}`);

  var raw = `{
    \n  \"guid\": \"${guid}\",
    \n  \"externalId\": \"${externalId}\",
    \n  \"app\": \"${app}\",
    \n  \"created_at\": \"${createdAt}\",
    \n  \"type\": \"${type}\",
    \n  \"lex\": ${JSON.stringify(lexAccess)},
    \n  \"certificate\": ${JSON.stringify(certificates)}
    \n}`;

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  fetch(`${window.API_SIGN_GATEWAY}/CertifiedSms/${userId}/sms/addorupdate`, requestOptions)
    .then(response => {
      response.text()
    })
    .then(result => null)
    .catch(error => console.log('error', error));
}

/*
   _____          _     _____               ____             _                  _ 
  / ____|        | |   |  __ \             |  _ \           | |                | |
 | |     ___ _ __| |_  | |  | | ___   ___  | |_) | __ _  ___| | _____ _ __   __| |
 | |    / _ \ '__| __| | |  | |/ _ \ / __| |  _ < / _` |/ __| |/ / _ \ '_ \ / _` |
 | |___|  __/ |  | |_  | |__| | (_) | (__  | |_) | (_| | (__|   <  __/ | | | (_| |
  \_____\___|_|   \__| |_____/ \___/ \___| |____/ \__,_|\___|_|\_\___|_| |_|\__,_|
                                                                                  
*/ 

export const getUserCertifiedDocuments = async (userId, auth) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");
    myHeaders.append("Authorization", `${auth}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${window.API_SIGN_GATEWAY}/CertifiedDocuments/${userId}`, requestOptions)
      .then(response => response.json())
      .then(result => resolve(result.data.documents.sort((a,b) => (a.created_at > b.created_at) ? -1 : ((b.created_at > a.created_at) ? 1 : 0))))
      .catch(error => {
        reject(error);
      });
  })
}

export const createUserCertifiedDocument = async (userId, auth, documents = []) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "text/plain");
  myHeaders.append("Content-Type", "application/json-patch+json");
  myHeaders.append("Content-Type", "text/plain");
  myHeaders.append("Authorization", `${auth}`);

  var raw = `{
  \n  "user\": \"${userId}\",
  \n  \"documents\": ${JSON.stringify(documents)}
  \n}`;

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  fetch(`${window.API_SIGN_GATEWAY}/CertifiedDocuments/addUser`, requestOptions)
    .then(response => {
      if (response.ok){
        return response.text();
      } else {
        throw `${response.text()}`;
      }
    })
    .then(result => null)
    .catch(error => console.log('error', error));
}

// Adds or updates a certified document to a user collection
export const addOrUpdateCertifiedDocument = async (userId, externalId, guid, app, createdAt, email, name, size, crc, auth) => {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "text/plain");
  myHeaders.append("Content-Type", "application/json-patch+json");
  myHeaders.append("Content-Type", "text/plain");
  myHeaders.append("Authorization", `${auth}`);

  var raw = `{
    \n  \"guid\": \"${guid}\",
    \n  \"externalId\": \"${externalId}\",
    \n  \"crc\": \"${crc}\",
    \n  \"created_at\": \"${createdAt}\",
    \n  \"email\": \"${email}\",
    \n  \"name\": \"${name}\",
    \n  \"size\": \"${size}\",
    \n  \"app\": \"${app}\",
    \n}`;

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  fetch(`${window.API_SIGN_GATEWAY}/CertifiedDocuments/${userId}/document/addorupdate`, requestOptions)
    .then(response => {
      if (response.ok){
        return response.text();
      } else {
        throw `${response.text()}`;
      }
    })
    .then(result => null)
    .catch(error => console.log('error', error));
}

export function preloadCertifiedDocuments (dispatch, userId, auth) {
  return new Promise((resolve, reject) => {
    getUserCertifiedDocuments(userId, auth)
    .then(documents => {
      dispatch(preDownloadDocuments(documents));
      resolve(documents);
    })
    .catch(error => {
      reject(error);
    });
  }) 
}

// END OF LEFEBVRE SIGNATURE API CALLS


/*
  LEFEBVRE SIGNATURE BACKEND GATEWAY SIGNATURIT API CALLS
*/
/*
   _____ _                   _              _ _               _____ _                   _                       
  / ____(_)                 | |            (_) |             / ____(_)                 | |                      
 | (___  _  __ _ _ __   __ _| |_ _   _ _ __ _| |_   ______  | (___  _  __ _ _ __   __ _| |_ _   _ _ __ ___  ___ 
  \___ \| |/ _` | '_ \ / _` | __| | | | '__| | __| |______|  \___ \| |/ _` | '_ \ / _` | __| | | | '__/ _ \/ __|
  ____) | | (_| | | | | (_| | |_| |_| | |  | | |_            ____) | | (_| | | | | (_| | |_| |_| | | |  __/\__ \
 |_____/|_|\__, |_| |_|\__,_|\__|\__,_|_|  |_|\__|          |_____/|_|\__, |_| |_|\__,_|\__|\__,_|_|  \___||___/
            __/ |                                                      __/ |                                    
           |___/                                                      |___/                                     

*/

export function preloadFirstSignatures(dispatch, client, userId, auth){
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `${auth}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${window.API_SIGN_GATEWAY}/Signaturit/signatures/${client}/${userId}/getSignatures/offset/0`, requestOptions)
      .then(response => response.json())
      .then(result => {
        let signatures = calculateStatus(result);
        dispatch(preDownloadSignatures(signatures));
        resolve(signatures);
      })
      .catch(error => {
        reject(error);
      });
  })
}

// Preloads all signatures associated with an account calling internal proxy api
export function preloadSignatures2(dispatch, client, userId, auth) {
  return new Promise((resolve, reject) => {

    let offset = 0;

    getSignatures(client, userId, auth, offset)
    .then(signatures => {
      if (signatures.length > 0 && signatures[0].err !== undefined){
        throw (signatures[0].err)
      } else {
        signatures = calculateStatus(signatures);
        dispatch(preDownloadSignatures(signatures));
        resolve(signatures);
      }
      
      
    })
    .catch(error => {
      reject(error);
    });
  })
}

export const getSignatures = async (client, user, auth, offset, signatures = []) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `${auth}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${window.API_SIGN_GATEWAY}/Signaturit/signatures/${client}/${user}/getSignatures/offset/${offset}`, requestOptions)
      .then(response => response.json()
        // {
        //   if (response.ok){
        //     return response.json();
        //   } else {
        //     return response.text().then(result => {throw result});
        //   }
        // }
      )
      .then(result => {
        signatures = signatures.concat(result);
        if (result.length === 100){
          resolve(getSignatures(client, user, auth, offset + result.length, signatures));
        } else {
          resolve(signatures);
        }
      })
      .catch(error => {
        reject(error);
      });
  })
}

export const searchSignatures = async (client, user, auth, field, value, offset) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `${auth}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${window.API_SIGN_GATEWAY}/Signaturit/signatures/${client}/${user}/search/${field}/${value}/offset/${offset}`, requestOptions)
      .then(response => 
        {
          if (response.ok){
            return response.json();
          } else {
            return response.text().then(result => {throw result});
          }
        }
      )
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  })
}

// Creates a new signature calling internal proxy api
//export const createSignature2 = async (recipients, subject, body, files, filesData, reminders, expiration, lefebvreId, guid, brandingId, auth) => {
  export const createSignature2 = async (client, recipients, cc, subject, body, files, pagesConfig, reminders, expiration, user, guid, brandingId, auth, roles) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");
    myHeaders.append("Content-Type", "application/json-patch+json");
    myHeaders.append("Content-Type", "text/plain");
    myHeaders.append("Authorization", `${auth}`);

    var jsonObject = {};
    var recipientsData = [];
    var ccData = [];
    var filesData = [];
    var customFieldsData = [];
    var remindConfig = '';
    var expirationConfig = '';
    var signAllPagesCoords = [];
    var rolesConfig = '';

    switch (reminders[0]) {
      case -1:
        remindConfig = 'notConfigured';
        break;
      case 1:
        remindConfig = 'Daily';
        break;
      case 7:
        remindConfig = 'Weekly';
        break;
      default:
        remindConfig = `Custom:${reminders[0]}`;
        break;
    }

    switch (expiration){
      case -1:
        expirationConfig = 'notConfigured';
        break;
      case 0:
        expirationConfig = 'never';
        break;
      default:
        expirationConfig = expiration;
        break;
    }

    //var fileData = '';

    jsonObject.recipients = recipients;

    cc.forEach(recipient => {
      ccData.push({name: recipient.address.split('@')[0], email: recipient.address})
    });
    jsonObject.cc = ccData;
    //filesData.push({file: filesData, fileName: files.name})
    // files.forEach(file => {
    //   filesData.push({file: file, fileName: file.name})
    // });
    files.forEach(file => {
      filesData.push({file: file.content, fileName: file.fileName})
      if (pagesConfig === 2){ // firma en todas las hojas
        let numRecipients = recipients.length;
        let numPages = file.pages;
        let element = '';
        for (let i = 0; i < numRecipients; i++) {
          for (let x = 0; x < numPages; x++) {
            element = `recipients[${i}][widgets][${x}][page]`;
            signAllPagesCoords.push({param: element, value: x + 1});
            element = `recipients[${i}][widgets][${x}][left]`;
            signAllPagesCoords.push({param: element, value: coordinates[numRecipients-1].left});
            element = `recipients[${i}][widgets][${x}][top]`;
            signAllPagesCoords.push({param: element, value: coordinates[numRecipients-1].top[i]});
            element = `recipients[${i}][widgets][${x}][type]`;
            signAllPagesCoords.push({param: element, value: 'signature'});
            element = `recipients[${i}][widgets][${x}][height]`;
            signAllPagesCoords.push({param: element, value: coordinates[numRecipients-1].height});
            element = `recipients[${i}][widgets][${x}][width]`;
            signAllPagesCoords.push({param: element, value: coordinates[numRecipients-1].width});

          }
        }
      }
    })
    //jsonObject.files = [{file: filesData, fileName: files.name}];
    jsonObject.files = filesData;
    jsonObject.coordinates = signAllPagesCoords;
    jsonObject.deliveryType = (signAllPagesCoords.length > 0 ? 'email' : '');

    //Roles config:
    recipients.forEach((recipient, i) => {
      rolesConfig += `${recipient.name}:${i}:${recipient.role}:${recipient.signatureType}:${recipient.doubleAuthType}:${recipient.doubleAuthInfo}|`;
    });

    customFieldsData.push({name: "lefebvre_id", value: user});
    customFieldsData.push({name: "lefebvre_guid", value: guid});
    customFieldsData.push({name: "subject", value: subject});
    customFieldsData.push({name: "body", value: body});
    customFieldsData.push({name: "reminders", value: remindConfig});
    customFieldsData.push({name: "expiration", value: expirationConfig});
    customFieldsData.push({name: "roles", value: rolesConfig})
    // customFieldsData.push({name: "expiration", value: expiration});
    // customFieldsData.push({name: "reminders", value: reminders});
    jsonObject.customFields = customFieldsData;

    jsonObject.subject = subject;
    jsonObject.body = body;
    (reminders[0] !== -1) ? jsonObject.reminders = reminders : null;
    (expiration !== -1) ? jsonObject.expiration = expiration : null;
    jsonObject.brandingId = brandingId;


    var raw = JSON.stringify(jsonObject);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${window.API_SIGN_GATEWAY}/Signaturit/signatures/${client}/${user}/newSignature`, requestOptions)
      .then(response => {
        if (response.ok){
          return response.json();
        } else {
          return response.text();
        }
      })
      .then(result => {
        resolve(result)
      })
      .catch(error => {
        reject(error);
      });
  })
}

// Downloads a document that's been signed calling internal proxy api
export const downloadSignedDocument2 = (client, signId, docId, fileName, auth, user) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `${auth}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${window.API_SIGN_GATEWAY}/Signaturit/signatures/${client}/${user}/download/${signId}/signedDocument/${docId}`, requestOptions)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(error => console.log('error', error));
  })
}

// Downloads the trail information of a signed document calling internal proxy api
export const downloadTrailDocument2 = (client, signId, docId, fileName, auth, user) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `${auth}`);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  fetch(`${window.API_SIGN_GATEWAY}/Signaturit/signatures/${client}/${user}/download/${signId}/trailDocument/${docId}`, requestOptions)
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'audit-'+fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    })
    .catch(error => console.log('error', error));
}

// Downloads the attachments information of a signed document calling internal proxy api
export const downloadAttachments2 = (client, signId, docId, fileName, auth, user) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `${auth}`);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  fetch(`${window.API_SIGN_GATEWAY}/Signaturit/signatures/${client}/${user}/download/${signId}/attachments/${docId}`, requestOptions)
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'documentos-('+fileName+').zip');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    })
    .catch(error => console.log('error', error));
}

// Sends a reminder to pending signers calling internal proxy api
export const sendReminder2 = async (client, signatureId, auth, user) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `${auth}`);

  var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  redirect: 'follow'
  };

  fetch(`${window.API_SIGN_GATEWAY}/Signaturit/signatures/${client}/${user}/reminder/${signatureId}`, requestOptions)
  .then(response => response.text())
  .then(result => null)
  .catch(error => console.log('error', error));
}

// Cancels a signature calling internal proxy api
export const cancelSignature2 = async (client, signatureId, auth, user) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `${auth}`);

    var requestOptions = {
    method: 'PATCH',
    headers: myHeaders,
    redirect: 'follow'
    };

    fetch(`${window.API_SIGN_GATEWAY}/Signaturit/signatures/${client}/${user}/cancelSignature/${signatureId}`, requestOptions)
    .then(response => response.text())
    .then(result => {
      resolve(result);
    })
    .catch(error => {
      reject(error)
    });
  });
}

// Creates a new Branding calling internal proxy api
export const createBranding2 = async (client, template, auth, user) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");
    myHeaders.append("Content-Type", "application/json-patch+json");
    myHeaders.append("Content-Type", "text/plain");
    myHeaders.append("Authorization", `${auth}`);

    var jsonObject = {};

    jsonObject.application_texts = {
      sign_button: template.application_texts.sign_button,
      send_button: template.application_texts.send_button,
      open_sign_button: template.application_texts.open_sign_button,
      open_email_button: template.application_texts.open_email_button,
      terms_and_conditions: template.application_texts.terms_and_conditions
    }
    jsonObject.layout_color = template.layout_color;
    jsonObject.logo = template.logo;
    jsonObject.signature_color = template.signature_color;
    jsonObject.templates = {
      signatures_request: template.templates.signatures_request,
      signatures_receipt:  template.templates.signatures_receipt,
      pending_sign:  template.templates.pending_sign,
      document_canceled:  template.templates.document_canceled,
      request_expired:  template.templates.request_expired,
      emails_request : (template.templates.emails_request) ? template.templates.emails_request : ""
    }
    jsonObject.text_color = template.text_color
    jsonObject.show_survey_page = template.show_survey_page
    jsonObject.show_csv = template.show_csv
    jsonObject.show_biometric_hash = template.show_biometric_hash
    jsonObject.show_welcome_page = template.show_welcome_page

    var raw = JSON.stringify(jsonObject);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${window.API_SIGN_GATEWAY}/Signaturit/signatures/${client}/${user}/newBranding`, requestOptions)
      .then(response => {
        if (response.ok){
          return response.json();
        }
        else {
          return response.text();//reject(response.text());
        }
      })
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error)
      });
  });
}


/*
   _____ _                   _              _ _              ______                 _ _     
  / ____(_)                 | |            (_) |            |  ____|               (_) |    
 | (___  _  __ _ _ __   __ _| |_ _   _ _ __ _| |_   ______  | |__   _ __ ___   __ _ _| |___ 
  \___ \| |/ _` | '_ \ / _` | __| | | | '__| | __| |______| |  __| | '_ ` _ \ / _` | | / __|
  ____) | | (_| | | | | (_| | |_| |_| | |  | | |_           | |____| | | | | | (_| | | \__ \
 |_____/|_|\__, |_| |_|\__,_|\__|\__,_|_|  |_|\__|          |______|_| |_| |_|\__,_|_|_|___/
            __/ |                                                                           
           |___/                                                                            

*/
// Retrieves all certified emails from Signaturit
export function preloadEmails(dispatch, client, user, auth) {
  return new Promise((resolve, reject) => {

    let offset = 0;

    getEmails(client, user, auth, offset)
    .then(emails => {
      emails = calculateStatusEmails(emails);
      var sortedEmails = emails.sort((a,b) => (a.created_at > b.created_at) ? -1 : ((b.created_at > a.created_at) ? 1 : 0));
      dispatch(preDownloadEmails(sortedEmails));
      resolve(sortedEmails);
    })
    .catch(error => {
      reject(error);
    });
  })
}

export const getEmails = async (client, user, auth, offset, emails = []) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `${auth}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${window.API_SIGN_GATEWAY}/Signaturit/emails/${client}/${user}/getEmails/offset/${offset}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        emails = emails.concat(result);
        if (result.length === 100){
          resolve(getEmails(client, user, auth, offset + result.length, emails));
        } else {
          resolve(emails);
        }
      })
      .catch(error => {
        reject(error);
      });
  })
}

export const searchEmails = async (client, user, auth, field, value, offset) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `${auth}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${window.API_SIGN_GATEWAY}/Signaturit/emails/${client}/${user}/search/${field}/${value}/offset/${offset}`, requestOptions)
      .then(response => 
        {
          if (response.ok){
            return response.json();
          } else {
            return response.text().then(result => {throw result});
          }
        }
      )
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  })
}

// Creates a new email calling internal proxy api
export const createEmail = async (client, recipients, cc, subject, body, files, user, guid, type, brandingId, auth) => {
    return new Promise((resolve, reject) => {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "text/plain");
      myHeaders.append("Content-Type", "application/json-patch+json");
      myHeaders.append("Content-Type", "text/plain");
      myHeaders.append("Authorization", `${auth}`);
  
      var jsonObject = {};
      var recipientsData = [];
      var ccData = [];
      var filesData = [];
      var customFieldsData = [];

      recipients.forEach(recipient => {
        recipientsData.push({name: ' ', email: recipient.address})
      })
      jsonObject.recipients = recipientsData;
    
      // cc.forEach(recipient => {
      //   ccData.push({name: ' ', email: recipient.address})
      // });
      jsonObject.cc = ccData;

      files.forEach(file => {
        filesData.push({file: file.content, fileName: file.fileName})
      })
      jsonObject.files = filesData;

      jsonObject.certificationType = type;
  
  
      customFieldsData.push({name: "lefebvre_id", value: user});
      customFieldsData.push({name: "lefebvre_guid", value: guid});
      customFieldsData.push({name: "subject", value: subject});
      customFieldsData.push({name: "body", value: body});
      customFieldsData.push({name: "certification_type", value: type})
      jsonObject.customFields = customFieldsData;
  
      jsonObject.subject = subject;
      jsonObject.body = body;
      jsonObject.brandingId = brandingId;
  
      var raw = JSON.stringify(jsonObject);
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
  
      fetch(`${window.API_SIGN_GATEWAY}/Signaturit/emails/${client}/${user}/newEmail`, requestOptions)
        .then(response => {
          if (response.ok){
            return response.json();
          } else {
            throw `${response.text()}`;
          }
        })
        .then(result => {
          resolve(result)
        })
        .catch(error => {
          reject(error);
        });
    })
}
  
// Downloads the trail information of a certified email calling internal proxy api
export const downloadCertificationDocument = (client, emailId, certificationId, fileName, auth, user) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `${auth}`);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  fetch(`${window.API_SIGN_GATEWAY}/Signaturit/emails/${client}/${user}/download/${emailId}/certificate/${certificationId}`, requestOptions)
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'audit-'+fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    })
    .catch(error => console.log('error', error));
}

/*

   _____ _                   _              _ _               _____               
  / ____(_)                 | |            (_) |             / ____|              
 | (___  _  __ _ _ __   __ _| |_ _   _ _ __ _| |_   ______  | (___  _ __ ___  ___ 
  \___ \| |/ _` | '_ \ / _` | __| | | | '__| | __| |______|  \___ \| '_ ` _ \/ __|
  ____) | | (_| | | | | (_| | |_| |_| | |  | | |_            ____) | | | | | \__ \
 |_____/|_|\__, |_| |_|\__,_|\__|\__,_|_|  |_|\__|          |_____/|_| |_| |_|___/
            __/ |                                                                 
           |___/                                                                  

*/

export function preloadSms(dispatch, client, user, auth) {
  return new Promise((resolve, reject) => {

    let offset = 0;

    getSms(client, user, auth, offset)
    .then(sms => {
      sms = calculateStatusSms(sms);
      var sortedSms = sms.sort((a,b) => (a.created_at > b.created_at) ? -1 : ((b.created_at > a.created_at) ? 1 : 0));
      dispatch(preDownloadSmsList(sortedSms));
      resolve(sortedSms);
    })
    .catch(error => {
      reject(error);
    });
  })
}

export const getSms = async (client, user, auth, offset, sms = []) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `${auth}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${window.API_SIGN_GATEWAY}/Signaturit/sms/${client}/${user}/getSms/offset/${offset}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        sms = sms.concat(result);
        if (result.length === 100){
          resolve(getSms(client, user, auth, offset + result.length, sms));
        } else {
          resolve(sms);
        }
      })
      .catch(error => {
        reject(error);
      });
  })
}

export const searchSms = async (client, user, auth, field, value, offset) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `${auth}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${window.API_SIGN_GATEWAY}/Signaturit/sms/${client}/${user}/search/${field}/${value}/offset/${offset}`, requestOptions)
      .then(response => 
        {
          if (response.ok){
            return response.json();
          } else {
            return response.text().then(result => {throw result});
          }
        }
      )
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  })
}

// Creates a new sms calling internal proxy api
export const createSms = async (client, recipients, body, files, user, guid, type, auth, validPhoneNumbers) => {
    return new Promise((resolve, reject) => {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "text/plain");
      myHeaders.append("Content-Type", "application/json-patch+json");
      myHeaders.append("Content-Type", "text/plain");
      myHeaders.append("Authorization", `${auth}`);
  
      var jsonObject = {};
      var recipientsData = [];
      var filesData = [];
      var customFieldsData = [];
      var userAdditionalInfo = '';

      recipients.forEach((recipient, i) => {
        var name = (recipient.name === null || recipient.name === undefined || recipient.name === '') ? '' : recipient.name;
        var email = (recipient.email === null || recipient.email === undefined || recipient.email === '') ? '' : recipient.email;
        var phone = validPhoneNumbers[i].normalizedPhone;
        recipientsData.push({name: name, phone: phone})
        userAdditionalInfo += `i=${i}:phone=${validPhoneNumbers[i].cleanPhone}:name=${(recipient.name === '') ? '-' : name}:email=${(recipient.email === '') ? '-' : email}|`
      })
      jsonObject.recipients = recipientsData;
    
      files.forEach(file => {
        filesData.push({file: file.content, fileName: file.fileName})
      })
      jsonObject.files = filesData;

      jsonObject.certificationType = type;
  
  
      customFieldsData.push({name: "lefebvre_id", value: user});
      customFieldsData.push({name: "lefebvre_guid", value: guid});
      customFieldsData.push({name: "body", value: body.innerText});
      customFieldsData.push({name: "certification_type", value: type});
      customFieldsData.push({name: "additional_info", value: userAdditionalInfo})
      jsonObject.customFields = customFieldsData;
  
      jsonObject.body = (type === 'open_document' || type === 'open_every_document') ? `${body.innerText} {{short_url}}` : body.innerText;  

      var raw = JSON.stringify(jsonObject);
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
  
      fetch(`${window.API_SIGN_GATEWAY}/Signaturit/sms/${client}/${user}/newSms`, requestOptions)
        .then(response => {
          if (response.ok){
            return response.json();
          } else {
            return response.text();
          }
        })
        .then(result => {
          resolve(result)
        })
        .catch(error => {
          reject(error);
        });
    })
}
  
// Downloads the trail information of a certified sms calling internal proxy api
export const downloadSmsCertificationDocument = (client, smsId, certificationId, fileName, auth, user) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `${auth}`);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  fetch(`${window.API_SIGN_GATEWAY}/Signaturit/sms/${client}/${user}/download/${smsId}/certificate/${certificationId}`, requestOptions)
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'audit-'+fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    })
    .catch(error => console.log('error', error));
}

/*
   _____ _                   _              _ _               _____          _   _  __ _          _   _____                 
  / ____(_)                 | |            (_) |             / ____|        | | (_)/ _(_)        | | |  __ \                
 | (___  _  __ _ _ __   __ _| |_ _   _ _ __ _| |_   ______  | |     ___ _ __| |_ _| |_ _  ___  __| | | |  | | ___   ___ ___ 
  \___ \| |/ _` | '_ \ / _` | __| | | | '__| | __| |______| | |    / _ \ '__| __| |  _| |/ _ \/ _` | | |  | |/ _ \ / __/ __|
  ____) | | (_| | | | | (_| | |_| |_| | |  | | |_           | |___|  __/ |  | |_| | | | |  __/ (_| | | |__| | (_) | (__\__ \
 |_____/|_|\__, |_| |_|\__,_|\__|\__,_|_|  |_|\__|           \_____\___|_|   \__|_|_| |_|\___|\__,_| |_____/ \___/ \___|___/
            __/ |                                                                                                           
           |___/                                                                                                            
*/

export const createCertifiedDocument = async (client, userId, guid, files, auth) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");
    myHeaders.append("Content-Type", "application/json-patch+json");
    myHeaders.append("Content-Type", "text/plain");
    myHeaders.append("Authorization", `${auth}`);

    var jsonObject = {};
    var filesData = [];
    
    files.forEach(file => {
      filesData.push({file: file.content, fileName: file.fileName, contentType: file.contentType})
    })

    jsonObject.user = userId;
    jsonObject.guid = guid;
    jsonObject.app = i18n.t('signaturesGrid.origin');
    jsonObject.files = filesData;

    var raw = JSON.stringify(jsonObject);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${window.API_SIGN_GATEWAY}/Signaturit/documentCertification/${client}/${userId}/newDocument/storeInDb`, requestOptions)
      .then(response => {
        if (response.ok){
          return response.json();
        } else {
          throw `${response.text()}`;
        }
      })
      .then(result => {
        resolve(result)
      })
      .catch(error => {
        reject(error);
      });
  })
}

export const downloadCertifiedDocumentAudit = (client, id, fileName, auth, userId) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `${auth}`);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  fetch(`${window.API_SIGN_GATEWAY}/Signaturit/documentCertification/${client}/${userId}/download/audit/${id}`, requestOptions)
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      const extension = fileName.split('.').pop();
      link.href = url;
      link.setAttribute('download', `audit-${extension === 'pdf' ? fileName : fileName+'.pdf'}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    })
    .catch(error => console.log('error', error));
}

export const downloadCertfiedDocumentCopy = (client, id, fileName, auth, userId) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `${auth}`);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  fetch(`${window.API_SIGN_GATEWAY}/Signaturit/documentCertification/${client}/${userId}/download/${id}`, requestOptions)
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    })
    .catch(error => console.log('error', error));
}


/*
  HELPER FUNCTIONS
*/

// Función para detectar y decodificar nombres de archivo codificados
function decodeFileName(encodedFileName) {
  // Patrón para detectar cadenas codificadas en formato RFC 2047
  // =?charset?encoding?encoded-text?=
  const pattern = /=\?([^?]*)\?([BQ])\?([^?]*)\?=/gi;

  if (!pattern.test(encodedFileName)) {
      return encodedFileName; // Retorna el original si no está codificado
  }

  // Reinicia el lastIndex del regex ya que test() lo modifica
  pattern.lastIndex = 0;

  return encodedFileName.replace(pattern, (match, charset, encoding, text) => {
      try {
          if (encoding.toUpperCase() === 'B') {
              // Decodifica Base64
              const decodedText = atob(text);
              // Convierte los bytes a texto según el charset
              return new TextDecoder(charset).decode(
                  Uint8Array.from(decodedText, c => c.charCodeAt(0))
              );
          } else if (encoding.toUpperCase() === 'Q') {
              // Decodifica Quoted-Printable
              return decodeQuotedPrintable(text, charset);
          }
      } catch (error) {
          console.error('Error decodificando:', error);
          return match; // Retorna el texto original si hay error
      }
      return match;
  });
}

function decodeQuotedPrintable(text, charset) {
  // Reemplaza _ por espacio y decodifica caracteres hexadecimales
  const decodedText = text
      .replace(/_/g, ' ')
      .replace(/=([0-9A-F]{2})/gi, (_, hex) => 
          String.fromCharCode(parseInt(hex, 16))
      );
  
  // Convierte al charset especificado
  return new TextDecoder(charset).decode(
      Uint8Array.from(decodedText, c => c.charCodeAt(0))
  );
}

const calculateStatus = (signatures) => {
  let numSigners = 0;
  let numCompleted = 0;
  let numInProgress = 0;
  let numCancelled = 0;
  let numRejected = 0;
  let numExpired = 0;
  let numError = 0;
  let numInQueue = 0;
  let numSigning = 0;
  signatures.map(signature => {
    numSigners = signature.documents.length;
      signature.documents.map( document => {
      switch (document.status) {
        case 'completed':
          numCompleted += 1;
          break;
        case 'ready':
          numInProgress += 1;
          break;
        case 'canceled':
          numCancelled += 1;
          break;
        case 'declined':
          numRejected += 1;
          break;
        case 'expired':
          numExpired += 1;
          break;
        case 'error':
          numError += 1;
          break;
        case 'in_queue':
          numInQueue += 1;
          break;
        case 'signing':
          numSigning += 1;
          break;
        default:
          break;
      }

      if (document.file && document.file.name) {
        const decodedFileName = decodeFileName(document.file.name);
        if (decodedFileName) {
          document.file.name = decodedFileName;
        }
      }
    })
    if (numSigners === numCompleted){
      signature.status = 'completed';
    } else if (numSigners > 0 && numCompleted < numSigners && numCancelled === 0 && numRejected === 0 && numExpired === 0 && numInQueue === 0 && numSigning === 0 && numError === 0){
      signature.status = 'ready';
    } else if (numSigners > 0 && numCancelled > 0){
      signature.status = 'canceled';
    } else if (numSigners > 0 && numRejected > 0){
      signature.status = 'declined';
    } else if (numSigners > 0 && numExpired > 0){
      signature.status = 'expired';
    } else if (numSigners > 0 && numError > 0){
      signature.status = 'error';
    } else if (numSigners > 0 && numInQueue > 0){
      signature.status = 'pending';
    } else if (numSigners > 0 && numSigning > 0){
      signature.status = 'signing'
    }
    numSigners = 0;
    numCompleted = 0;
    numInProgress = 0;
    numCancelled = 0;
    numRejected = 0;
    numExpired = 0;
    numError = 0;
    numInQueue = 0;
    numSigning = 0;
  })
  return signatures;
};

const getRecipientsEmail= (email) => {
  var lookup = {};
  var items = email.certificates;
  var result = [];

  for (var item, i = 0; item = items[i++];) {
    var name = item.email;

    if (!(name in lookup)) {
      lookup[name] = 1;
      result.push(name);
    }
  }
  return result;
}

const getRecipientsSms= (sms) => {
  var lookup = {};
  var items = sms.certificates;
  var result = [];

  for (var item, i = 0; item = items[i++];) {
    var phone = item.phone;

    if (!(phone in lookup)) {
      lookup[phone] = 1;
      result.push(phone);
    }
  }
  return result;
}

const getDocumentsCertification = (emailOrSms, service) => {
  var documents = [];
  var firstRecipientCertificates
  if (service == 'sms'){
    firstRecipientCertificates = emailOrSms.certificates.filter(c => c.phone === emailOrSms.certificates[0].phone);
  } else {
    firstRecipientCertificates = emailOrSms.certificates.filter(c => c.email === emailOrSms.certificates[0].email);
  }

  firstRecipientCertificates.forEach(certificate => {
    if (!documents.find(document => document == JSON.stringify({file: certificate.file, id: certificate.id}))){
      if (certificate.file !== undefined){
        documents.push(JSON.stringify({file: certificate.file, id: certificate.id}));
      }
    }
  });
  return documents;
}

const countCertificationEvents = (emailOrSms) => {
  var counter = 0;
  emailOrSms.certificates.forEach(certificate => {
    if (certificate.events.find(ev => ev.type == "certification_completed")){
      counter++;
    }
  });
  return counter;
}

const countErrorEvents = (emailOrSms) => {
  var counter = 0;
  emailOrSms.certificates.forEach(certificate => {
    if (certificate.status === 'error'){
      counter++;
    }
  });
  return counter;
}

const countDistinctEmails = (email) => {
  var distinctEmails = getRecipientsEmail(email)
  return distinctEmails.length;
}

const countDistinctSms = (sms) => {
  var distinctPhones = getRecipientsSms(sms)
  return distinctPhones.length;
}

const countDistinctDocuments = (emailOrSms, service) => {
  var distinctDocuments = getDocumentsCertification(emailOrSms, service)
  return distinctDocuments.length;
}

const calculateStatusEmails = (emails) => {
  // To do
  emails.forEach(email => {
    var numNodes = email.certificates.length;
    var numRecipients = countDistinctEmails(email);
    var numDocuments = countDistinctDocuments(email, 'email');
    var numCertifiedEvents = countCertificationEvents(email);
    var numErrorEvents = countErrorEvents(email);

    numDocuments = (numDocuments === 0) ? 1 : numDocuments;

    if (numErrorEvents > 0){
      email.status = 'error'
    } else if (numCertifiedEvents == numRecipients && numNodes == numRecipients * numDocuments){
      email.status = 'completed'
    } else {
      email.status = 'ready'
    }
    email.numDocuments = numDocuments;

    email.certificates.forEach(certificate => {
      if (certificate.file && certificate.file.name){
        const decodedFileName = decodeFileName(certificate.file.name);
        if (decodedFileName) {
          certificate.file.name = decodedFileName;
        }
      }
    });
  });
  return emails
}

const calculateStatusSms = (smsList) => {
  smsList.forEach(sms => {
    var numNodes = sms.certificates.length;
    var numRecipients = countDistinctSms(sms);
    var numDocuments = countDistinctDocuments(sms, 'sms');
    var numCertifiedEvents = countCertificationEvents(sms);
    var numErrorEvents = countErrorEvents(sms);
  
    numDocuments = (numDocuments === 0) ? 1 : numDocuments;

    if (numErrorEvents > 0){
      sms.status = 'error'
    } else if (numCertifiedEvents == numRecipients && numNodes == numRecipients * numDocuments){
      sms.status = 'completed'
    } else {
      sms.status = 'ready'
    }
    sms.numDocuments = numDocuments;

    sms.certificates.forEach(certificate => {
      if (certificate.file && certificate.file.name){
        const decodedFileName = decodeFileName(certificate.file.name);
        if (decodedFileName) {
          certificate.file.name = decodedFileName;
        }
      }
    });
  })
  return smsList
}

// END HELPER FUNCTIONS


export const getAttachmentLex = async (idUser, idDocument, idCompany, auth, env) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `${auth}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({"idUser":idUser,"idCompany":idCompany,"idDocument":idDocument});

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    // fetch(`${window.API_GATEWAY_LEX}/api/v1/Signatures/files/get?env=QA`, requestOptions)
    fetch(`${window.API_GATEWAY_LEX}/api/v1/lex/Signatures/files/get?env=${env}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  })
}

export const getAttachmentCen = async (userId, attachmentId, userApp) => {
  return new Promise((resolve, reject) => {
    var urlDocs = `${window.API_GATEWAY_CEN}/api/v1/cen/concepts/files/get?idNavisionUser=${userId}&idDocument=${attachmentId}&docType=document`;
    var urlReports = `${window.API_GATEWAY_CEN}/api/v1/cen/concepts/files/get?idNavisionUser=${userId}&idDocument=${attachmentId}&docType=report`;
    var url = (userApp === 'centinelaReport' ? urlReports : urlDocs);

    var myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");
    myHeaders.append("Content-Type", "application/json-patch+json");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    //userId = 'E1669460'; //Para pruebas

    fetch(url, requestOptions)
    .then(response => response.json())
    .then(result => {
      resolve(result);
    })
    .catch(error => {
      reject(error);
    });
  })
}

export const getAttachmentCus = async (Guid, auth) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${auth}`);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    // fetch(`${window.API_GATEWAY_LEX}/api/v1/Signatures/files/get?env=QA`, requestOptions)
    fetch(`${window.API_GATEWAY_CUS}/api/v1/ClientsSignatures/${Guid}/files/get`, requestOptions)
      .then(response => response.json())
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  })
}

export const cancelSignatureCen = async (guid) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");
    myHeaders.append("Content-Type", "application/json-patch+json");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    //fetch(`https://gks-lexbox-dev-apigwcen.lefebvre.es/api/v1/cen/signatures/cancelation/${guid}}`, requestOptions)
    fetch(`${window.API_GATEWAY_CEN}/api/v1/cen/signatures/cancelation/${guid}`, requestOptions)
    .then(response => response.json())
    .then(result => {
      resolve(result);
    })
    .catch(error => {
      reject(error);
    });
  })
}

export const cancelSignatureCus = async (guid, auth) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");
    myHeaders.append("Content-Type", "application/json-patch+json");
    myHeaders.append("Authorization", `Bearer ${auth}`);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    //fetch(`https://gks-lexbox-dev-apigwcus.lefebvre.es/api/v1/signatures/${guid}/Cancel`, requestOptions)
    fetch(`${window.API_GATEWAY_CUS}/api/v1/ClientsSignatures/${guid}/Cancel`, requestOptions)
    .then(response => response.json())
    .then(result => {
      resolve(result);
    })
    .catch(error => {
      reject(error);
    });
  })
}

export const cancelSignatureLex = async (guid, idCompany, idUser, auth, env) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    // myHeaders.append("Authorization", `Bearer ${auth}`);
    myHeaders.append("Accept", "text/plain");
    myHeaders.append("Content-Type", "application/json-patch+json");

    var raw = JSON.stringify({"idCompany":idCompany, "idUser":idUser});

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    // fetch(`${window.API_GATEWAY_LEX}/api/v1/signatures/cancel/${guid}?env=QA`, requestOptions)
    fetch(`${window.API_GATEWAY_LEX}/api/v1/lex/signatures/cancel/${guid}?env=${env}`, requestOptions)
    .then(response => response.json())
    .then(result => {
      resolve(result);
    })
    .catch(error => {
      reject(error);
    });
  })
}

export const notifyLex = async (guid, idCompany, idUser, recipients, auth, env) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    // myHeaders.append("Authorization", `Bearer ${auth}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "idCompany": idCompany,
      "idUser": idUser,
      "recipientsId": recipients
    });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  fetch(`${window.API_GATEWAY_LEX}/api/v1/lex/signatures/notify/${guid}?env=${env}`, requestOptions)
  // fetch(`${window.API_GATEWAY_LEX}/api/v1/signatures/notify/${guid}?env=QA`, requestOptions)
    .then(response => response.json())
    .then(result => {
      resolve(result);
    })
    .catch(error => {
      console.log('error', error)
      reject(error);
    });
  });
}

export const notifyCen = async (service, guid, docId, recipients, userApp) => {
  return new Promise((resolve, reject) => {
    var docType = userApp === 'centinelaReport' ? 'report' : 'document';
    var myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");
    myHeaders.append("Content-Type", "application/json-patch+json");

    var recipientsData = [];

    recipients.forEach(recipient => {
      (service === 'signature') 
        ? recipientsData.push({name: recipient.name, email: recipient.email})
        : (service === 'certifiedEmail') 
            ? recipientsData.push({name: recipient.name, email: recipient.address})
            : recipientsData.push({name: recipient.name, email: recipient.email, phone: recipient.phone})
    });

    var raw = JSON.stringify(recipientsData);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    }
    // localhost: https://localhost:44331/api/v1/Centinela
    fetch(`${window.API_GATEWAY_CEN}/api/v1/cen/signatures/notify/${service}/${guid}/${docType}/${docId}`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        return response.text();
      }
    })
    .then(result => {
      resolve(result);
    })
    .catch(error => {
      reject(error);
    });
  })
}

export const notifyCus = async ( guid, auth) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");
    myHeaders.append("Content-Type", "application/json-patch+json");
    myHeaders.append("Authorization", `Bearer ${auth}`);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    }
    // localhost: https://localhost:44331/api/v1/Centinela
    fetch(`${window.API_GATEWAY_CUS}/api/v1/ClientsSignatures/${guid}/notify/`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        return response.text();
      }
    })
    .then(result => {
      resolve(result);
    })
    .catch(error => {
      reject(error);
    });
  })
}

export const getContactsCentinelaSMS = async(user) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${window.API_GATEWAY_CEN}/api/v1/cen/smscontacts?idNavisionUser=${user}`, requestOptions)
    .then(response => {
      if (response.ok){
        return response.json();
      } else {
        return response.text()
      }}
    )
    .then(result => {
      result.data.forEach((contact, i) => contact.contactId = i);
      resolve(result);
    })
    .catch(error => {
      reject(error);
    });
  });
}

export const getContactsCentinela = async(user) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${window.API_GATEWAY_CEN}/api/v1/cen/userscontacts?idNavisionUser=${user}`, requestOptions)
    .then(response => {
      if (response.ok){
        return response.json();
      } else {
        return response.text()
      }}
    )
    .then(result => {
      result.data.forEach((contact, i) => contact.contactId = i);
      resolve(result);
    })
    .catch(error => {
      reject(error);
    });
  });
}


export const getContactsCustomerarea = async(idClienteLef, idClient, auth) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");
    myHeaders.append("Authorization", `Bearer ${auth}`);
    var client="";
    if(idClient!==undefined){
      client=`&idClient=${idClient}`
    }
    idClient = (idClient==undefined)?"":idClient;
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${window.API_GATEWAY_CUS}/api/v1/cli/contactsSignature/${idClienteLef}/searchPag?numPage=1&sizePage=9999${client}`, requestOptions)
    .then(response => {
      if (response.ok){
        return response.json();
      } else {
        return response.text()
      }}
    )
    .then(result => {
      result.data.contacts.forEach((contact, i) => contact.contactId = i);
      resolve(result);
    })
    .catch(error => {
      reject(error);
    });
  });
}

export const getContactsLexon = async(user, idCompany, db, env) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");
    myHeaders.append("Content-Type", "application/json-patch+json");

    // var raw = JSON.stringify({bbdd: db, idUser: user});

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      //body: raw,
      redirect: 'follow'
    }


    fetch(`${window.API_GATEWAY_LEX}/api/v1/lex/Contacts/${idCompany}/user/${user}/conn/${db}/all?env=${env}`, requestOptions)
    // fetch(`${window.API_GATEWAY_LEX}/api/v1/Contacts/${user}/${db}/all`, requestOptions)
    .then(response => {
      if (response.ok){
        return response.json();
      } else {
        throw `${response.text()}`;
      }}
    )
    .then(result => {
      resolve(result);
    })
    .catch(error => {
      reject(error);
    });
  })
}

export const getBBDDLexon = async (user, idNavision, env) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    }

    fetch(`${window.API_GATEWAY_LEX}/api/v1/lex/Lexon/user?entry=${user}&idNavision=${idNavision}&env=${env}`, requestOptions)
    // fetch(`${window.API_GATEWAY_LEX}/api/v1/Lexon/user?idUserNavision=${user}`, requestOptions)
    .then(response => {
      if (response.ok){
        return response.json();
      } else {
        throw `${response.text()}`;
      }}
    )
    .then(result => {
      resolve(result);
    })
    .catch(error => {
      reject(error);
    });
  })
}

export const getAvailableSignatures = async (companyId, numDocuments) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${window.API_UTILS_GATEWAY}/firm/client/${companyId}/numdocs/${numDocuments}/check`, requestOptions)
      .then(response => response.json())
      .then(result => resolve(result))
      //.then(result =>  resolve(true)) // Se pone para pruebas
      .catch(error => {
        reject(error);
      });
  })
}

export const getNumAvailableSignatures = async (companyId) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${window.API_UTILS_GATEWAY}/firm/client/${companyId}/checkAvailable`, requestOptions)
      .then(response => response.json())
      .then(result => resolve(result))
      .catch(error => {
        reject(error);
      });
  })
}

export const notifySignature = async (userId, companyId, numDocuments, type) => {
  return new Promise((resolve, reject) => {

    var requestOptions = {
      method: 'POST',
      redirect: 'follow'
    };

    fetch(`${window.API_UTILS_GATEWAY}/firm/client/${companyId}/user/${userId}/numdocs/${numDocuments}/type/${type}/add`, requestOptions)
      .then(response => response.text())
      .then(result => {
        resolve (result);
      })
      .catch(error => {
        reject(error);
      });
  })
}

export const verifyJwtSignature = async(token) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");
    myHeaders.append("Content-Type", "application/json-patch+json");

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: `"${token}"`,
      redirect: 'follow'
    };

    fetch(`${window.API_GATEWAY_LEX}/api/v1/utils/Lexon/token/validation?validateCaducity=false`, requestOptions)
    // fetch(`https://gks-lexbox-dev-apigwlex.lefebvre.es/api/v1/utils/Lexon/token/validation?validateCaducity=false`, requestOptions)
    .then(response => {
      if (response.ok){
        return response.json();
      } else {
        return response.json();
      }}
    )
    .then(result => {
      resolve(result.data.valid);
    })
    .catch(error => {
      reject(error);
    });
  })
}

export const getSignedToken = async (user, password, client) => {
  return new Promise((resolve, reject) => {
    // var myHeaders = new Headers();
    // myHeaders.append("Accept", "text/plain");
    // myHeaders.append("Content-Type", "multipart/form-data");

    var formdata = new FormData();
    let url;

    if (client === 'FR'){
      formdata.append('login', user);
      formdata.append('password', password);
      formdata.append("idApp", 3);
      formdata.append("addTerminatorToToken", true);
      url = `${window.API_UTILS_GATEWAY}/token/adsf`;
  
    } else {
      formdata.append(`login`, user);
      formdata.append(`password`, password);
      formdata.append("idApp", 0);
      formdata.append("addTerminatorToToken", true);
      url = `${window.API_UTILS_GATEWAY}/token/Get`;
    }
    
    var requestOptions = {
      method: 'POST',
      //headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
    .then(response => {
      if (response.ok){
        return response.json();
      } else {
        throw new Error("Status:" + response.status + ' ' + " Headers:" + response.headers);
      }}
    )
    .then(result => {
      resolve(result);
    })
    .catch(error => {
      reject(error);
    });
  });
}